table.treetable span.indenter {
  display: inline-block;
  margin: 0;
  padding: 0;
  text-align: right;

  /* Disable text selection of nodes (for better D&D UX) */
  user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;

  /* Force content-box box model for indenter (Bootstrap compatibility) */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  width: 19px;
}

table.treetable span.indenter a {
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  text-decoration: none;
  width: 19px;
}
